<!-- 新版首页 -->
<template>
  <div class="fierce_look">
    <hard></hard>
    <div class="content">
      <img src="~@/assets/new_yingshi/observe-home-img.png" alt="" class="img-view" />
      <div class="center-view">
        <span class="ls-text">联视</span>
      </div>
      <div class="command-item diaodu-view" @click="openBlank('#/fckernelVideos')">
        <span class="spq-text">视频墙</span>
        <div class="row-count">
          <div class="item">
            <div class="v-line v-line-blue"></div>
            <div class="count-view">
              <span class="value">{{ uavOnline + uavOffline }}</span>
              <span class="lable">总数</span>
            </div>
          </div>
          <div class="item">
            <div class="v-line v-line-green"></div>
            <div class="count-view">
              <span class="value">{{ uavOnline }}</span>
              <span class="lable">在线</span>
            </div>
          </div>
        </div>
      </div>
      <div class="command-item shebei-manager" @click="openBlank('#/fckernel')">
        <span>无人机应用</span>
        <div class="row-count">
          <div class="item">
            <div class="v-line v-line-blue"></div>
            <div class="count-view">
              <span class="value">{{ uavOnline + uavOffline }}</span>
              <span class="lable">总数</span>
            </div>
          </div>
          <div class="item">
            <div class="v-line v-line-green"></div>
            <div class="count-view">
              <span class="value"> {{ uavOnline }}</span>
              <span class="lable">在线</span>
            </div>
          </div>
        </div>
      </div>
      <div class="command-item shebei-jiance" @click="openBlank('#/fckernel?page=2')">
        <span>机巢应用</span>
        <div class="row-count">
          <div class="item">
            <div class="v-line v-line-blue"></div>
            <div class="count-view">
              <span class="value">{{ nestOnline + nestOffline }}</span>
              <span class="lable">总数</span>
            </div>
          </div>
          <div class="item">
            <div class="v-line v-line-green"></div>
            <div class="count-view">
              <span class="value"> {{ nestOnline }}</span>
              <span class="lable">在线</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 顶部
// import hard from "./newHeard/index.vue";
import API from "@/api";
import hard from "./components/header/index.vue";
export default {
  components: {
    hard,
  },
  data () {
    return {
      card_angle: 360, //控制外围卡片旋转 旋转度数
      card_style: null, // 外围卡片旋转样式(css)
      card_time: null, // 外围卡片旋转定时器
      earth_angle: 0, // 控制内部地球旋转    旋转度数
      earth_style: null, // 内部地球旋转样式(css)
      earth_time: null, // 内部地球旋转定时器
      stop_time: null, // 旋转暂停定时器
      timeOne: null, //卡片移动到正面的定时器
      timeTwo: null,
      timeThree: null,
      save: null, //保存要旋转的角度
      takeOff: true, //误判锁
      clicktake: false,
      leftTake: true,
      rightTake: true,
      metux: false,

      uavOnline: 0,
      uavOffline: 0,
      nestOnline: 0,
      nestOffline: 0,

      startedTurn: false, //已经开始旋转
    };
  },
  created () {
    this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", false);
  },
  mounted () {
    document.title = "联视";
    this.turn();
    // API.FCKERNEL.ListUAV({}).then(res => {
    API.DEVICE.List({ cate: 1 }).then((res) => {
      if (res.length > 0) {
        this.uavOnline = res[0].online;
        this.uavOffline = res[0].offline;
      }
    });

    API.NEST.getNestList().then((res) => {
      this.nestOnline = res.onLineCount;
      this.nestOffline = res.offLineCount;
    });
  },
  methods: {
    tolink () {
      this.$router.push({ path: "/observe/home_v1", query: { page: 1 } });
    },
    openBlank (url) {
      window.open(url, "_blank");
    },
    left_btn () {
      console.log("left_btn");
      if (!this.startedTurn) {
        return;
      }
      if (this.leftTake == false) {
        return;
      }
      this.rightTake = false;
      this.leftTake = false;
      this.close_time();
      this.clicktake = true;
      if (this.card_angle < 71) {
        this.save = 338;
        this.fahrbereit();
        this.close_time();
        return;
      } else if (this.card_angle == 71) {
        this.save = 338;
        this.fahrbereit();
        this.close_time();
        return;
      }
      if (this.card_angle < 205) {
        this.save = 71;
        this.fahrbereit();
        this.close_time();
        return;
      } else if (this.card_angle == 205) {
        this.save = 71;
        this.fahrbereit();
        this.close_time();
        return;
      }
      if (this.card_angle < 338) {
        this.save = 205;
        this.fahrbereit();
        this.close_time();
        return;
      } else if (this.card_angle == 338) {
        this.save = 205;
        this.fahrbereit();
        this.close_time();
        return;
      } else {
        this.rightTake = true;
        this.leftTake = true;
      }
    },
    right_btn () {
      console.log("right_btn");
      if (!this.startedTurn) {
        return;
      }
      if (this.rightTake == false) {
        return;
      }
      this.close_time();
      this.rightTake = false;
      this.leftTake = false;
      this.clicktake = true;
      if (this.card_angle < 71) {
        this.save = 71;
        this.fahrbereit_against();
        this.close_time();
        return;
      } else if (this.card_angle == 71) {
        this.save = 205;
        this.fahrbereit_against();
        this.close_time();
        return;
      }
      if (this.card_angle < 205) {
        this.save = 205;
        this.fahrbereit_against();
        this.close_time();
        return;
      } else if (this.card_angle == 205) {
        this.save = 338;
        this.fahrbereit_against();
        this.close_time();
        return;
      }
      if (this.card_angle < 338) {
        this.save = 338;
        this.fahrbereit_against();
        this.close_time();
        return;
      } else if (this.card_angle == 338) {
        this.save = 71;
        this.fahrbereit_against();
        this.close_time();
        return;
      } else {
        this.rightTake = true;
        this.leftTake = true;
      }
    },
    // 控制卡片一鼠标悬浮效果
    cardOne () {
      // 首先执行暂停方法
      this.close_time();
    },
    cardTwo () {
      // 首先执行暂停方法
      this.close_time();
    },
    cardThree () {
      this.close_time();
    },
    // 将卡片旋转到正面的方法
    fahrbereit () {
      clearTimeout(this.timeOne);
      this.timeOne = null;
      // 开启一次性定时器
      this.timeOne = setTimeout(() => {
        // 判断当前角度是否不等于预设的角度值
        if (this.card_angle != this.save) {
          // console.log(this.card_angle, this.save);
          //  旋转
          this.card_angle = this.card_angle - 1;
          if (this.card_angle == 0) {
            this.card_angle = 360;
          }
          let a = this.card_angle;
          this.card_style = `rotateY(${a}deg)`;

          this.earth_angle++;
          if (this.earth_angle == 360) {
            this.earth_angle = 0;
          }
          let b = this.earth_angle;
          this.earth_style = `rotateY(${b}deg)`;
          // 如果不等于预设角度值重复调用
          this.fahrbereit();
        } else {
          // 如果等于则关闭定时器

          this.close_time();
          this.leftTake = true;
          this.clicktake = true;
          this.rightTake = true;
          return;
        }
      }, 5);
    },
    // 将卡片旋转到正面的方法(反)
    fahrbereit_against () {
      clearTimeout(this.timeTwo);
      this.timeTwo = null;
      // 开启一次性定时器
      this.timeTwo = setTimeout(() => {
        // 判断当前角度是否不等于预设的角度值
        if (this.card_angle != this.save) {
          //   console.log(this.card_angle, this.save);
          //  旋转
          this.card_angle++;
          if (this.card_angle == 360) {
            this.card_angle = 0;
          }
          let a = this.card_angle;
          this.card_style = `rotateY(${a}deg)`;

          this.earth_angle = this.earth_angle - 1;
          if (this.earth_angle == 0) {
            this.earth_angle = 360;
          }
          let b = this.earth_angle;
          this.earth_style = `rotateY(${b}deg)`;
          // 如果不等于预设角度值重复调用
          this.fahrbereit_against();
        } else {
          // 如果等于则关闭定时器;
          this.close_time();
          this.rightTake = true;
          this.clicktake = true;
          this.leftTake = true;
          return;
        }
      }, 5);
    },
    // 鼠标离开
    quit () {
      this.turn();
    },
    deviate () {
      if (this.clicktake == false) {
        return;
      }
      if (this.takeOff == false) {
        return;
      }
      this.takeOff = false;
      clearTimeout(this.timeThree);
      this.timeThree = null;
      this.timeThree = setTimeout(() => {
        this.turn();
        this.takeOff = true;
        this.clicktake = false;
        clearTimeout(this.timeThree);
        this.timeThree = null;
      }, 3000);
    },
    // 旋转动画控制
    turn () {
      this.close_time();
      // 外部卡片样式正转
      this.card_time = setInterval(() => {
        // console.log("888");
        this.card_angle = this.card_angle - 1;
        if (this.card_angle == 0) {
          this.card_angle = 360;
        }
        let zorn = this.card_angle;
        this.card_style = `rotateY(${zorn}deg)`;
      }, 50); // 控制旋转速度
      // 内部地球反转(这样内部可以相对静止)
      this.earth_time = setInterval(() => {
        this.earth_angle++;
        if (this.earth_angle == 360) {
          this.earth_angle = 0;
        }
        let zorn = this.earth_angle;
        this.earth_style = `rotateY(${zorn}deg)`;
      }, 50); // 控制旋转速度

      setTimeout(() => {
        this.startedTurn = true;
      }, 2000); //初始化后2秒才允许响应左右点击事件
    },
    // 关闭定时器
    close_time () {
      clearInterval(this.card_time);
      clearInterval(this.earth_time);
      this.card_time = null;
      this.earth_time = null;
      // this.startedTurn = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fierce_look {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #1f2030;
}

.content {
  width: 100vw;
  position: absolute;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;

  .img-view {
    height: 81vh;
    width: 97.5vw;
    margin-bottom: 3vh;
  }

  .center-view {
    position: absolute;
    bottom: 36vh;
    right: 0;
    width: 100%;
    text-align: center;
    z-index: 2;

    span {
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 56px;
      letter-spacing: 3px;
      margin-bottom: 219px;
      background: linear-gradient(142deg,
          #fdffff 0%,
          #5cdfef 0%,
          #5fe0f7 52%,
          #f8feff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .command-item {
    width: 24.4vw;
    height: 33vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 46px;
    cursor: pointer;
    gap: 20px;

    .row-count {
      display: flex;
      flex-direction: row;
      gap: 50px;

      .item {
        display: flex;
        flex-direction: row;
        gap: 5px;

        .v-line {
          width: 6px;
          height: 40px;
        }

        .v-line-blue {
          background: #00ffff;
        }

        .v-line-green {
          background: #19e210;
        }

        .count-view {
          display: flex;
          flex-direction: column;

          .value {
            font-size: 24px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #ffffff;
            line-height: 28px;
          }

          .lable {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .command-item:hover {
    background-image: url("~@/assets/new_yingshi/observe-home-hover.png");
    background-size: 100% 100%;
  }

  .diaodu-view {
    position: absolute;
    left: 4vw;
    // top: 20vh;
    top: 18vh;
  }

  .shebei-manager {
    position: absolute;
    right: 3.5vw;
    // top: 9vh;
    top: 7vh;
  }

  .shebei-jiance {
    position: absolute;
    right: 3.5vw;
    // top: 37vh;
    top: 31vh;
  }

  .spq-text {
    margin-left: -3vh;
  }

  .ls-text {
    margin-left: -5.5vh;
  }
}</style>
